import { makeObservable, observable, action, runInAction } from 'mobx';
import FirebaseService from '../services/firebaseService';
import { collection } from '../constant';

export class PremiumUserList {
    userList = []
    selectedUser = {}
    loading = false

    constructor() {
        makeObservable(this, {
            userList: observable,
            selectedUser: observable,
            loading: observable,
            toggleLoading: action,
            resetState: action
        });
        this.FireBase = new FirebaseService();
    }

    toggleLoading() {
        this.loading = !this.loading;
    }

    resetState() {
        this.selectedUser = {};
    }

    async loadUser() {
        try {
            this.toggleLoading();
            const documentList = await this.FireBase.getActivePremiumUser(collection.PREMIUMUSER);
            const data = documentList.docs.map((eachDoc) => ({ ...eachDoc.data(), id: eachDoc.id }));
            runInAction(() => {
                this.userList = data;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async loadSelectedUser(docId) {
        try {
            this.toggleLoading();
            const documentObj = await this.FireBase.get(collection.PREMIUMUSER, docId);
            const data = { ...documentObj.data(), id: documentObj.id };
            if (data && data.status && data.expiryDate) {
                if (data.status === 'active' && data.expiryDate.toDate() >= new Date()) {
                    data.pricing = 'premium';
                }
                else {
                    data.pricing = 'free';
                }
            }
            runInAction(() => {
                this.selectedUser = data;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

}