import React, { Component } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import './scss/style.scss';
import { DataContext } from './store/store';
import ProtectedRoute from './reusable/ProtectedRoute';
// import { CloudMessaging } from './services';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const ResetPassword = React.lazy(() => import('./views/pages/reset-password/reset-password'));
class App extends Component {
  async componentDidMount() {
    /** These functions are needed to subscribe to the notifications of Cloud Messaging
     * These are not needed in the web
     * They are only implemented on the mobile section
     */
    // CloudMessaging.getToken();
    // const payload = await CloudMessaging.onMessaging();
  }

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/reset-password" name="Reset Password" render={props => <ResetPassword {...props} />} />
              <ProtectedRoute path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    )
  }
}
App.contextType = DataContext;

export default App;
