import firebase from '../config/firebase-config';
import { collection } from '../constant';

export default class FirebaseService {
    constructor() {
        this.db = firebase.firestore();
    }

    get currentTime() {
        return firebase.firestore.FieldValue.serverTimestamp();
    }

    /**
     * Method to return all the data of the collection
     * Returns promise
     * @param {*} collection
     * @return {*} 
     * @memberof FirebaseService
     */
    getAll(collection, order = true) {
        if (order) {
            return this.db.collection(collection).orderBy('sequence').orderBy('createdAt', 'desc').get();
        }
        else {
            return this.db.collection(collection).get();
        }
        
    }

    get(collection, docId) {
        return this.db.collection(collection).doc(docId).get();
    }

    getByCondition(collection, attribute, operator, value) {
        return this.db.collection(collection).where(attribute, operator, value).orderBy('sequence').orderBy('createdAt', 'desc').get();
    }

    getByConditionWithoutSequence(collection, attribute, operator, value) {
        return this.db.collection(collection).where(attribute, operator, value).get();
    }

    getActivePremiumUser(collection) {
        return this.db.collection(collection).where('status', '==', 'active').where('expiryDate', '>=', new Date()).get();
    }

    getUserById(userId) {
        return this.db.collection(collection.USER).where('userId', '==', userId).where('userType', '==', 1).get();
    }

    create(collection, payload) {
        return this.db.collection(collection).add(payload);
    }

    update(collection, docId, payload) {
        return this.db.collection(collection).doc(docId).update(payload);
    }

    delete(collection, docId) {
        return this.db.collection(collection).doc(docId).delete();
    }

    getAllSubCollection(collection, subCollection, docId, sequence=true) {
        if (!sequence) {
            return this.db.collection(collection).doc(docId)
            .collection(subCollection).orderBy('createdAt', 'desc').get();
        }
        return this.db.collection(collection).doc(docId)
            .collection(subCollection).orderBy('sequence').orderBy('createdAt', 'desc').get();
    }

    getSubCollection(collection, subCollection, docId, subDocId) {
        return this.db.collection(collection).doc(docId)
            .collection(subCollection).doc(subDocId).get();
    }

    createSubCollection(collection, subCollection, payload, docId) {
        return this.db.collection(collection).doc(docId)
            .collection(subCollection).add(payload);
    }

    updateSubCollection(collection, subCollection, payload, docId, subDocId) {
        return this.db.collection(collection).doc(docId)
            .collection(subCollection).doc(subDocId).update(payload);
    }

    deleteSubCollection(collection, subCollection, docId, subDocId) {
        return this.db.collection(collection).doc(docId)
            .collection(subCollection).doc(subDocId).delete();
    }
}