import { makeObservable, observable, action, runInAction } from 'mobx';
import FirebaseService from '../services/firebaseService';
import { Auth } from '../services';
import { collection } from '../constant';
import { getBucketFolder } from '../helpers';

export class CategoryList {
    categoryList = []
    selectedCategory = {}
    loading = false
    createSuccess = undefined
    createFail = undefined
    updateSuccess = undefined
    updateFail = undefined

    constructor() {
        makeObservable(this, {
            categoryList: observable,
            selectedCategory: observable,
            loading: observable,
            createSuccess: observable,
            createFail: observable,
            updateSuccess: observable,
            updateFail: observable,
            toggleLoading: action,
            resetState: action
        });
        this.FireBase = new FirebaseService();
    }

    toggleLoading() {
        this.loading = !this.loading;
    }

    resetState() {
        this.createSuccess = undefined;
        this.createFail = undefined;
        this.updateSuccess = undefined;
        this.updateFail = undefined;
    }

    async loadCategory() {
        try {
            this.toggleLoading();
            const documentList = await this.FireBase.getAll(collection.CATEGORY);
            const data = documentList.docs.map((eachDoc) => ({ ...eachDoc.data(), id: eachDoc.id }));
            runInAction(() => {
                this.categoryList = data;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async loadSelectedCategory(docId) {
        try {
            this.toggleLoading();
            const documentObj = await this.FireBase.get(collection.CATEGORY, docId);
            const data = { ...documentObj.data(), id: documentObj.id };
            runInAction(() => {
                this.selectedCategory = data;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async createCategory(payload) {
        try {
            this.toggleLoading();
            let { thumbnail, ...data } = payload;
            data = { ...data, createdAt: this.FireBase.currentTime, updatedAt: this.FireBase.currentTime, createdBy: Auth.loggedInUser.userId }
            
            const { id } = await this.FireBase.create(collection.CATEGORY, data);
            
            const updatePayload = (id && { thumbnail: `${getBucketFolder()}/category-thumbnail-${id}` }) || {};
            thumbnail && await this.FireBase.update(collection.CATEGORY, id, updatePayload);
            runInAction(() => {
                this.createSuccess = true;
                this.loading = false;
            });
            return id;
        }
        catch (e) {
            runInAction(() => {
                this.createFail = true;
                this.loading = false;
            });
        }
    }

    async editCategory(payload, docId) {
        try {
            this.toggleLoading();

            payload = { ...payload, updatedAt: this.FireBase.currentTime, updatedBy: Auth.loggedInUser.userId };
            await this.FireBase.update(collection.CATEGORY, docId, payload);

            runInAction(() => {
                this.updateSuccess = true;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.updateFail = true;
                this.loading = false;
            });
        }
    }

    async deleteCategory(docId) {
        try {
            this.toggleLoading();
            await this.FireBase.delete(collection.CATEGORY, docId);
            runInAction(() => {
                this.categoryList = this.categoryList.filter(eachCategory => eachCategory.id !== docId);
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

}