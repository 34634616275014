import { makeObservable, observable, action, runInAction } from 'mobx';
import firebase from '../config/firebase-config';
import FirebaseService from '../services/firebaseService';
import { Auth } from '../services';
import { collection } from '../constant';
import { dateToString } from '../helpers';

export class UserList {
    userList = []
    selectedUser = {}
    loading = false
    updateSuccess = undefined
    updateFail = undefined

    constructor() {
        makeObservable(this, {
            userList: observable,
            selectedUser: observable,
            loading: observable,
            updateSuccess: observable,
            updateFail: observable,
            toggleLoading: action,
            resetState: action
        });
        this.FireBase = new FirebaseService();
    }

    toggleLoading() {
        this.loading = !this.loading;
    }

    resetState() {
        this.updateSuccess = undefined;
        this.updateFail = undefined;
        this.selectedUser = {};
    }

    async loadUser() {
        try {
            this.toggleLoading();
            const [documentList, premiumUserList] = await Promise.all([
                this.FireBase.getByConditionWithoutSequence(collection.USER, 'userId', '!=', Auth.loggedInUserId),
                this.FireBase.getActivePremiumUser(collection.PREMIUMUSER)
            ]);
            const data = documentList.docs.map((eachDoc) => ({ ...eachDoc.data(), id: eachDoc.id }));
            premiumUserList.docs.map((eachDoc) => {
                const findUserIndex = data.findIndex((eachData) => eachData.userId === eachDoc.id)
                if (findUserIndex > -1) data[findUserIndex].isPremium = true;
            });
            runInAction(() => {
                this.userList = data;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async loadSelectedUser(docId) {
        try {
            this.toggleLoading();
            const documentObj = await this.FireBase.get(collection.USER, docId);
            const data = { ...documentObj.data(), id: documentObj.id };
            if (data.createdAt) data.createdAt = dateToString(firebase, data.createdAt)
            if (data.updatedAt) data.updatedAt = dateToString(firebase, data.updatedAt)
            
            runInAction(() => {
                this.selectedUser = data;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    // async createCategory(payload) {
    //     try {
    //         this.toggleLoading();
    //         let { thumbnail, ...data } = payload;
    //         data = { ...data, createdAt: this.FireBase.currentTime, updatedAt: this.FireBase.currentTime, createdBy: Auth.loggedInUser.userId }
            
    //         const { id } = await this.FireBase.create(collection.CATEGORY, data);
            
    //         const updatePayload = (id && { thumbnail: `${getBucketFolder()}/category-thumbnail-${id}` }) || {};
    //         thumbnail && await this.FireBase.update(collection.CATEGORY, id, updatePayload);
    //         runInAction(() => {
    //             this.createSuccess = true;
    //             this.loading = false;
    //         });
    //         return id;
    //     }
    //     catch (e) {
    //         runInAction(() => {
    //             this.createFail = true;
    //             this.loading = false;
    //         });
    //     }
    // }

    async editUser(payload, docId) {
        try {
            this.toggleLoading();

            payload = { ...payload, updatedAt: this.FireBase.currentTime, updatedBy: Auth.loggedInUser.userId };
            await this.FireBase.update(collection.USER, docId, payload);

            runInAction(() => {
                this.updateSuccess = true;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.updateFail = true;
                this.loading = false;
            });
        }
    }

    // async deleteCategory(docId) {
    //     try {
    //         this.toggleLoading();
    //         await this.FireBase.delete(collection.CATEGORY, docId);
    //         runInAction(() => {
    //             this.categoryList = this.categoryList.filter(eachCategory => eachCategory.id !== docId);
    //             this.loading = false;
    //         })
    //     }
    //     catch (e) {
    //         runInAction(() => {
    //             this.loading = false;
    //         });
    //     }
    // }

}