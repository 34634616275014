
const titleCase = string => {
    let result = 'N/A';
    if (string) {
      result = string.charAt(0).toUpperCase() + string.slice(1);
    }
    return result;
};

const getBadge = state => {
    switch (state) {
      case 'published': return 'success'
      case 'draft': return 'warning'
      default: return 'primary'
    }
}

const getPremium = state => {
  switch (state) {
    case 'premium': return 'success'
    case 'free': return 'warning'
    default: return 'warning'
  }
}

const getBucketFolder = () => {
  const { REACT_APP_ENV } = process.env;
  let folder = 'local';

  if (REACT_APP_ENV === 'DEVELOPMENT') {
    folder = 'development';
  }

  if (REACT_APP_ENV === 'PRODUCTION') {
    folder = 'production';
  }

  return folder;
}

// Function to convert the timestamp to the date string
// It will handle both the Firebase and Date timestamp
const dateToString = (firebase, dateTimeStamp) => {
  let dateString = null;
  if (dateTimeStamp && dateTimeStamp instanceof firebase.firestore.Timestamp) {
    dateString = dateTimeStamp.toDate().toDateString();
  }
  else if (dateTimeStamp && new Date(parseInt(dateTimeStamp)) instanceof Date) {
    dateString = new Date(parseInt(dateTimeStamp)).toDateString();
  }
  return dateString;
}

export { 
  titleCase, 
  getBadge,
  getBucketFolder,
  getPremium,
  dateToString
};