const collection = {
    CATEGORY: 'categories',
    BOOK: 'books',
    CHAPTER: 'chapters',
    CONFIG: 'configs',
    USER: 'users',
    LIKEDBY: 'likedBy',
    COMMENT: 'comments',
    PREMIUMUSER: 'premiumUsers'
};

const Filter = {
    STATE: ['draft', 'published']
};

const PAGE_SIZE = 10;

const CATEGORY_FIELDS = [
    {
        key: 'sequence',
        _style: { width: '10%' }
    },
    {
        key: 'name'
    },
    {
        key: 'description'
    },
    {
        key: 'state',
        _style: { width: '10%' }
    },
    {
        key: 'action',
        label: 'Actions',
        sorter: false,
        filter: false,
        _style: { width: '10%' }
    }
]

const USER_FIELDS = [
    {
        key: 'S.N',
        sorter: false,
        filter: false,
        _style: { width: '10%' }
    },
    {
        key: 'firstName'
    },
    {
        key: 'middleName'
    },
    {
        key: 'lastName'
    },
    {
        key: 'email'
    },
    {
        key: 'userType'
    },
    {
        key: 'action',
        label: 'Actions',
        sorter: false,
        filter: false,
        _style: { width: '10%' }
    }
]

export { collection, Filter, CATEGORY_FIELDS, USER_FIELDS, PAGE_SIZE };