import React from 'react';
import { BookList } from './bookList';
import { CategoryList } from './categoryList';
import { ChapterList } from './chapterList';
import { ConfigList } from './configList';
import { UserLikeList } from './userLikeList';
import { UserComment } from './userComment';
import { AuthStore } from './authStore';
import { UserList } from './userList';
import { PremiumUserList } from './premiumUserList';


export default class Store {
    constructor() {
        this.AuthStoreObj = new AuthStore();
        this.Category = new CategoryList();
        this.Book = new BookList();
        this.Chapter = new ChapterList();
        this.UserLikeList = new UserLikeList();
        this.UserComment = new UserComment();
        this.Config = new ConfigList();
        this.UserList = new UserList();
        this.PremiumUserList = new PremiumUserList();
    }
}

export const DataContext = React.createContext();

export const StoreProvider = ({children, store}) => {
    return (
        <DataContext.Provider value={store}>{children}</DataContext.Provider>
    );
}