import { makeObservable, observable, action, runInAction } from 'mobx';
import FirebaseService from '../services/firebaseService';
import { Auth } from '../services';
import { collection } from '../constant';
import { getBucketFolder } from '../helpers';

export class BookList {
    bookList = []
    selectedBook = {}
    loading = false
    createSuccess = undefined
    createFail = undefined
    updateSuccess = undefined
    updateFail = undefined

    constructor() {
        makeObservable(this, {
            bookList: observable,
            selectedBook: observable,
            loading: observable,
            createSuccess: observable,
            createFail: observable,
            updateSuccess: observable,
            updateFail: observable,
            toggleLoading: action,
            increaseCommentCount: action,
            decreaseCommentCount: action,
            resetState: action
        });
        this.FireBase = new FirebaseService();
    }

    toggleLoading() {
        this.loading = !this.loading;
    }

    resetState() {
        this.createSuccess = undefined;
        this.createFail = undefined;
        this.updateSuccess = undefined;
        this.updateFail = undefined;
    }

    increaseCommentCount(bookId) {
        this.loading = true;
        if (this.selectedBook && this.selectedBook[bookId]) {
            this.selectedBook.totalComments = this.selectedBook.totalComments + 1;
        }
        this.loading = false;
    }

    decreaseCommentCount(bookId) {
        this.loading = true;
        if (this.selectedBook && this.selectedBook.id === bookId && this.selectedBook.totalComments) {
            this.selectedBook.totalComments = +this.selectedBook.totalComments - 1;
        }
        this.loading = false;
    }

    async loadBook() {
        try {
            this.toggleLoading();
            const documentList = await this.FireBase.getAll(collection.BOOK);
            const data = documentList.docs.map((eachDoc) => ({ ...eachDoc.data(), id: eachDoc.id }));
            runInAction(() => {
                this.bookList = data;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async loadCategoryBook(categoryid) {
        try {
            this.toggleLoading();
            const documentList = await this.FireBase.getByCondition(collection.BOOK, 'categoryIds', 'array-contains', categoryid);
            const data = documentList.docs.map((eachDoc) => ({ ...eachDoc.data(), id: eachDoc.id }));
            runInAction(() => {
                this.bookList = data;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async loadSelectedBook(docId) {
        try {
            this.toggleLoading();
            const documentObj = await this.FireBase.get(collection.BOOK, docId);
            const data = { ...documentObj.data(), id: documentObj.id };
            runInAction(() => {
                this.selectedBook = data;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async createBook(payload) {
        try {
            this.toggleLoading();

            let { thumbnail, ...data } = payload;
            const searchTitle = data.name.length > 2 ? data.name.slice(0, 3) : data.name; // This field is created for the search purpose
            data = { ...data, searchTitle, createdAt: this.FireBase.currentTime, updatedAt: this.FireBase.currentTime, createdBy: Auth.loggedInUser.userId }
            const { id } = await this.FireBase.create(collection.BOOK, data);

            const updatePayload = (id && { thumbnail: `${ getBucketFolder() }/book-${id}/book-thumbnail-${id}` }) || {};
            thumbnail && await this.FireBase.update(collection.BOOK, id, updatePayload);
            runInAction(() => {
                this.createSuccess = true;
                this.loading = false;
            })
            return id;
        }
        catch (e) {
            runInAction(() => {
                this.createFail = true;
                this.loading = false;
            });
        }
    }

    async editBook(payload, docId) {
        try {
            this.toggleLoading();
            const searchTitle = payload.name.length > 2 ? payload.name.slice(0, 3) : payload.name; // This field is created for the search purpose
            payload = { ...payload, searchTitle, updatedAt: this.FireBase.currentTime, updatedBy: Auth.loggedInUser.userId };
            await this.FireBase.update(collection.BOOK, docId, payload);
            runInAction(() => {
                this.updateSuccess = true;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.updateFail = true;
                this.loading = false;
            });
        }
    }

    async deleteBook(docId) {
        try {
            this.toggleLoading();
            await this.FireBase.delete(collection.BOOK, docId);

            const chapterList = await this.FireBase.getAllSubCollection(collection.BOOK, collection.CHAPTER, docId);
            chapterList.forEach(eachChapter => eachChapter.ref.delete());
            runInAction(() => {
                this.bookList = this.bookList.filter(eachBook => eachBook.id !== docId);
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}