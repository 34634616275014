import firebase from '../config/firebase-config';

class CloudMessaging {

    constructor() {
        this.Messaging = firebase.messaging();
    }

    async registerWorker() {
        if ('serviceWorker' in navigator) {
            const registration = await navigator.serviceWorker.register('./firebase-messaging-sw.js');
            console.log('Registration success', registration);
        }
    }

    async getToken() {
        try {
            await this.registerWorker();
            const token = await this.Messaging.getToken(process.env.REACT_APP_FIREBASE_VAPIDKEY);
            console.log(token);
        }
        catch (error) {
            console.log(error);
        }
    }

    onMessaging() {
        return new Promise((resolve) => {
            this.Messaging.onMessage((payload) => {
                resolve(payload);
            });
        });
    }
}

export default new CloudMessaging();