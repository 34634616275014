import { s3 } from '../config';

class S3Service {
    uploadFile(params) {
        return s3.putObject(params).promise();
    }

    deleteFolderS3(params) {
        return s3.deleteObjects(params).promise();
    }

    deleteFile(params) {
        return s3.deleteObject(params).promise();
    }

    listFileFromBucket(params) {
        return s3.listObjectsV2(params).promise();
    }

    async deleteFolder(params) {
        const { Bucket, Prefix } = params;

        const objectList = await this.listFileFromBucket(params);
        if (objectList.Contents.length === 0) Promise.resolve(true);

        const deleteParams = {
            Bucket,
            Delete: { Objects: [] }
        };

        objectList.Contents.forEach(({ Key }) => {
            deleteParams.Delete.Objects.push({ Key });
        });

        await this.deleteFolderS3(deleteParams);

        // eslint-disable-next-line no-undef
        if (objectList.IsTruncated) await deleteFolder(Bucket, Prefix);
    }

}

export default new S3Service();