import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import Store, { StoreProvider } from './store/store'; 
// import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './store'
import { icons } from './assets/icons';
React.icons = icons;

const StoreObj = new Store();

ReactDOM.render(
  <StoreProvider store={StoreObj}>
    <Provider store={store}>
      <App/>
    </Provider>
  </StoreProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
