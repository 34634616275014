import { makeObservable, observable, action, runInAction } from 'mobx';
import FirebaseService from '../services/firebaseService';
import { collection } from '../constant';

export class ConfigList {
    configList = []
    selectedConfig = {}
    loading = false

    constructor() {
        makeObservable(this, {
            configList: observable,
            selectedConfig: observable,
            loading: observable,
            toggleLoading: action
        });
        this.FireBase = new FirebaseService();
    }

    toggleLoading() {
        this.loading = !this.loading;
    }

    async loadConfig() {
        try {
            this.toggleLoading();
            const documentList = await this.FireBase.getAll(collection.CONFIG);
            const data = documentList.docs.map((eachDoc) => ({ ...eachDoc.data(), id: eachDoc.id }));
            runInAction(() => {
                this.configList = data;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async loadSelectedConfig(docId) {
        try {
            this.toggleLoading();
            const documentObj = await this.FireBase.get(collection.CONFIG, docId);
            const data = { ...documentObj.data(), id: documentObj.id };
            runInAction(() => {
                this.selectedConfig = data;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async editConfig(payload, docId) {
        try {
            this.toggleLoading();
            payload = { ...payload, updatedAt: this.FireBase.currentTime };
            await this.FireBase.update(collection.CONFIG, docId, payload);
            runInAction(() => {
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}