import { action, makeObservable, observable, runInAction } from 'mobx';
import FirebaseService from '../services/firebaseService';
import { Auth } from '../services';
import { collection } from '../constant';
import { getBucketFolder } from '../helpers';

export class ChapterList {
    chapterList = []
    selectedChapter = {}
    loading = false
    createSuccess = undefined
    createFail = undefined
    updateSuccess = undefined
    updateFail = undefined

    constructor() {
        makeObservable(this, {
            chapterList: observable,
            selectedChapter: observable,
            loading: observable,
            createSuccess: observable,
            createFail: observable,
            updateSuccess: observable,
            updateFail: observable,
            toggleLoading: action,
            removeChapterList: action,
            resetState: action
        });
        this.FireBase = new FirebaseService();
    }

    toggleLoading() {
        this.loading = !this.loading;
    }

    removeChapterList() {
        this.chapterList = [];
    }

    resetState() {
        this.createSuccess = undefined;
        this.createFail = undefined;
        this.updateSuccess = undefined;
        this.updateFail = undefined;
    }

    async loadChapter(bookId) {
        try {
            this.toggleLoading();

            const documentList = await this.FireBase.getAllSubCollection(collection.BOOK, collection.CHAPTER, bookId);
            const data = documentList.docs.map((eachDoc) => ({ ...eachDoc.data(), id: eachDoc.id }));

            runInAction(() => {
                this.chapterList = data;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async loadSelectedChapter(bookId, chapterId) {
        try {
            this.toggleLoading();
            const documentObj = await this.FireBase.getSubCollection(collection.BOOK, collection.CHAPTER, bookId, chapterId);
            const data = { ...documentObj.data(), id: documentObj.id };
            runInAction(() => {
                this.selectedChapter = data;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async createChapter(payload, docId) {
        try {
            this.toggleLoading();

            let { thumbnail, audio, ...data } = payload;
            data = { ...data, createdAt: this.FireBase.currentTime, updatedAt: this.FireBase.currentTime, createdBy: Auth.loggedInUser.userId }
            const { id } = await this.FireBase.createSubCollection(collection.BOOK, collection.CHAPTER, data, docId);

            const thumbnailPayload = (docId && id && { thumbnail: `${ getBucketFolder() }/book-${docId}/chapter-thumbnail-${id}` }) || {};
            thumbnail && await this.FireBase.updateSubCollection(collection.BOOK, collection.CHAPTER, thumbnailPayload, docId, id);

            const audioPayload = (docId && id && { audio: `${ getBucketFolder() }/book-${docId}/audio-${id}` }) || {};
            audio && await this.FireBase.updateSubCollection(collection.BOOK, collection.CHAPTER, audioPayload, docId, id);
            runInAction(() => {
                this.createSuccess = true;
                this.loading = false;
            })
            return id;
        }
        catch (e) {
            runInAction(() => {
                this.createFail = true;
                this.loading = false;
            });
        }
    }

    async editChapter(payload, bookId, chapterId) {
        try {
            this.toggleLoading();

            payload = { ...payload, updatedAt: this.FireBase.currentTime, updatedBy: Auth.loggedInUser.userId };
            await this.FireBase.updateSubCollection(collection.BOOK, collection.CHAPTER, payload, bookId, chapterId);

            runInAction(() => {
                this.updateSuccess = true;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.updateFail = true;
                this.loading = false;
            });
        }
    }

    async deleteChapter(bookId, chapterId) {
        try {
            this.toggleLoading();
            await this.FireBase.deleteSubCollection(collection.BOOK, collection.CHAPTER, bookId, chapterId);
            runInAction(() => {
                this.chapterList = this.chapterList.filter(eachChapter => eachChapter.id !== chapterId);
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}