import { action, makeObservable, observable, runInAction } from 'mobx';
import firebase from '../config/firebase-config';
import FirebaseService from '../services/firebaseService';
import { collection } from '../constant';
import { dateToString } from '../helpers';

export class UserLikeList {
    userList = [];
    loading = false;

    constructor() {
        makeObservable(this, {
            userList: observable,
            loading: observable,
            toggleLoading: action,
        });
        this.FireBase = new FirebaseService();
    }

    toggleLoading() {
        this.loading = !this.loading;
    }

    async loadUserList(bookId) {
        try {
            this.toggleLoading();

            const documentList = await this.FireBase.getAllSubCollection(collection.BOOK, collection.LIKEDBY, bookId, false);
            const data = documentList.docs.map((eachDoc) => {
                const eachData = eachDoc.data();
                const payload = {
                    userId: eachDoc.id,
                    name: 'N/A',
                    createdAt: 'N/A'
                };
                if (eachData && eachData.username) payload.name = eachData.username;
                if (eachData && eachData.createdAt) payload.createdAt = dateToString(firebase, eachData.createdAt);
                return payload;
            });

            runInAction(() => {
                this.userList = data;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}