import { makeObservable, observable, action, runInAction } from 'mobx';
import firebase from '../config/firebase-config';
import Auth from '../services/authService';
import FirebaseService from '../services/firebaseService';

export class AuthStore {
    loggedInUser = {}
    loading = false

    constructor() {
        makeObservable(this, {
            loggedInUser: observable,
            loading: observable,
            toggleLoading: action,
            loadLoggedInUserDetail: action
        });
        this.FireBase = new FirebaseService();
    }

    toggleLoading() {
        this.loading = !this.loading;
    }

    checkLogin() {
        return new Promise((resolve, reject) => {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    resolve(user);
                }
                else {
                    reject('no-user');
                }
            })
        })
    }

    loadLoggedInUserDetail(documentList) {
        const data = documentList.docs.map((eachDoc) => ({ ...eachDoc.data(), id: eachDoc.id }));
        this.loggedInUser = data[0];
        this.loading = false;
    }

    async loginUser(username, password) {
        const userCredential = await Auth.login(username, password);
        if (userCredential && userCredential.user){
            return await this.FireBase.getUserById(userCredential.user.uid);
        }
        else {
            throw new Error('Not found the user in the list');
        }
        
    }

    async getUserDetail(userId) {
        try {
            this.toggleLoading();
            const documentList = await this.FireBase.getUserById(userId);
            this.loadLoggedInUserDetail(documentList);
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async logOutUser() {
        await Auth.signout();
        runInAction(() => this.loggedInUser = {})
    }
}