import { action, makeObservable, observable, runInAction } from 'mobx';
import firebase from '../config/firebase-config';
import FirebaseService from '../services/firebaseService';
import { Auth } from '../services';
import { collection } from '../constant';
import { dateToString } from '../helpers';

export class UserComment {
    commentList = [];
    selectedComment = {};
    loading = false;
    updateSuccess = undefined
    updateFail = undefined
    deleteSuccess = false;
    deleteFail = false;

    constructor() {
        makeObservable(this, {
            commentList: observable,
            loading: observable,
            updateSuccess: observable,
            updateFail: observable,
            deleteSuccess: observable,
            deleteFail: observable,
            toggleLoading: action,
            resetState: action
        });
        this.FireBase = new FirebaseService();
    }

    toggleLoading() {
        this.loading = !this.loading;
    }

    resetState() {
        this.deleteSuccess = undefined;
        this.deleteFail = undefined;
        this.selectedComment = {};
    }

    async loadCommentList(bookId) {
        try {
            this.toggleLoading();

            const documentList = await this.FireBase.getAllSubCollection(collection.BOOK, collection.COMMENT, bookId, false);
            // const data = documentList.docs.map((eachDoc) => ({ ...eachDoc.data(), id: eachDoc.id }));
            const data = documentList.docs.map((eachDoc) => {
                const eachData = eachDoc.data();
                const payload = {
                    id: eachDoc.id,
                    comment: 'N/A',
                    createdAt: 'N/A',
                    updatedAt: 'N/A',
                    createdBy: 'N/A',
                    updatedBy: 'N/A'
                };
                if (eachData.text) payload.comment = eachData.text;
                if (eachData.createdAt) payload.createdAt = dateToString(firebase, eachData.createdAt);
                if (eachData.updatedAt) payload.updatedAt = dateToString(firebase, eachData.updatedAt);
                if (eachData.userName) payload.createdBy = eachData.userName;
                if (eachData.updatedBy) payload.updatedBy = eachData.updatedBy;
                return payload;
            });

            runInAction(() => {
                this.commentList = data;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async loadSelectedComment(bookId, commentId) {
        try {
            this.toggleLoading();
            const documentObj = await this.FireBase.getSubCollection(collection.BOOK, collection.COMMENT, bookId, commentId);
            const eachData = documentObj.data();
            const data = {
                id: documentObj.id,
                comment: 'N/A',
                createdAt: 'N/A',
                updatedAt: 'N/A',
                createdBy: 'N/A',
                updatedBy: 'N/A'
            };
            if (eachData.text) data.comment = eachData.text;
            if (eachData.createdAt) data.createdAt = dateToString(firebase, eachData.createdAt);
            if (eachData.updatedAt) data.updatedAt = dateToString(firebase, eachData.updatedAt);
            if (eachData.userName) data.createdBy = eachData.userName;
            if (eachData.updatedBy) data.updatedBy = eachData.updatedBy;
            runInAction(() => {
                this.selectedComment = data;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async editComment(payload, bookId, commentId) {
        try {
            this.toggleLoading();

            payload = { ...payload, updatedAt: this.FireBase.currentTime, updatedBy: Auth.loggedInUserName, updatedByUserId: Auth.loggedInUser.userId };
            await this.FireBase.updateSubCollection(collection.BOOK, collection.COMMENT, payload, bookId, commentId);

            runInAction(() => {
                this.updateSuccess = true;
                this.loading = false;
            })
        }
        catch (e) {
            runInAction(() => {
                this.updateFail = true;
                this.loading = false;
            });
        }
    }

    async deleteComment(bookId, commentId) {
        try {
            this.toggleLoading();
            await this.FireBase.deleteSubCollection(collection.BOOK, collection.COMMENT, bookId, commentId);
            runInAction(() => {
                this.commentList = this.commentList.filter(each => each.id !== commentId);
                this.loading = false;
                this.deleteSuccess = true;
            })
        }
        catch (e) {
            console.log(e);
            runInAction(() => {
                this.loading = false;
                this.deleteFail = true;
            });
        }
    }
}