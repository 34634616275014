/* eslint-disable no-restricted-globals */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-functions';
import 'firebase/messaging';

const ENVIRONMENTLIST = ['DEVELOPMENT', 'PRODUCTION'];
const { REACT_APP_ENV } = process.env;
const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: ENVIRONMENTLIST.includes(REACT_APP_ENV) ? process.env.REACT_APP_FIREBASE_AUTH_DOMAIN : '',
    projectId:  process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: ENVIRONMENTLIST.includes(REACT_APP_ENV) ? process.env.REACT_APP_FIREBASE_STORAGE_BUCKET : '',
    messagingSenderId: ENVIRONMENTLIST.includes(REACT_APP_ENV) ? process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID : '',
    appId:  ENVIRONMENTLIST.includes(REACT_APP_ENV) ? process.env.REACT_APP_FIREBASE_APP_ID : '',
    measurementId: ENVIRONMENTLIST.includes(REACT_APP_ENV) ? process.env.REACT_APP_FIREBASE_MEASUREMENT_ID  : ''
  };

firebase.initializeApp(config);

// const db = firebase.firestore();
// const auth = firebase.auth;

// if (location.hostname === 'localhost') {
//   db.useEmulator('localhost', 8080);
//   auth().useEmulator('http://localhost:9099/', { disableWarnings: true });
//   firebase.functions().useEmulator('localhost', 5001);
// }

export default firebase;