import firebase from '../config/firebase-config';

class AuthServiceFirebase {
    register(email, password) {
        return firebase.auth().createUserWithEmailAndPassword(email, password);
    }

    login(email, password) {
        return firebase.auth().signInWithEmailAndPassword(email, password);
    }

    get loggedInUserId() {
        const userObj = JSON.parse(localStorage.getItem('userid'));
        return userObj.userId;
    }

    get loggedInUser() {
        return JSON.parse(localStorage.getItem('userid'));
    }

    get loggedInUserName() {
        const userObj = JSON.parse(localStorage.getItem('userid'));
        let name = `${userObj.firstName} ${userObj.lastName}`;
        if (userObj.middleName) {
            name = `${userObj.firstName} ${userObj.middleName} ${userObj.lastName}`;
        }
        return name;
    }

    signout() {
        return firebase.auth().signOut();
    }

    reauthenticate(currentPassword) {
        const user = firebase.auth().currentUser;
        const cred = firebase.auth.EmailAuthProvider.credential(
            user.email, currentPassword);
        return user.reauthenticateWithCredential(cred);
    }

    changeEmail(newEmail) {
        const currentUser = firebase.auth().currentUser;
        return currentUser.updateEmail(newEmail);
    }

    changePassword(newPassword) {
        const currentUser = firebase.auth().currentUser;
        return currentUser.updatePassword(newPassword);
    }

    resetPassword(email) {
        return firebase.auth().sendPasswordResetEmail(email);
    }
}

export default new AuthServiceFirebase();