import { FirebaseService } from '.';
import { collection } from '../constant';

export default class DataMigrationService {
    constructor() {
        this.Firebase = new FirebaseService();
    }

    async addSearchFieldInBook() {
        try {
            const data = await this.Firebase.getAll(collection.BOOK);
            const updateBookPromise = data.docs.map((each) => { 
                const searchTitle = each.data().name.length > 2 ? each.data().name.slice(0, 3) : each.data().name;
                const payload = {
                    searchTitle
                };
                return this.Firebase.update(collection.BOOK, each.id, payload);
            });
            await Promise.all(updateBookPromise);
            console.log('Migration success!!');
        }
        catch (error) {
            console.log('Failed', error);
        }
    }
}